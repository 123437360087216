




















































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { BusinessTitleBanner } from '~/components/widgets';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import buildMeta from '~/libraries/meta';

@Component({
  components: {
    BusinessTitleBanner,
    FontAwesomeIcon
  },
  computed: {
    faArrowRight() {
      return faArrowRight;
    }
  }
})
export default class OpenfacePage extends Vue {
  en_link =
    'https://blog.beanfield.com/beanfield-technologies-expands-fiber-footprint-into-montreal-with-acquisition-of-openface/';
  fr_link =
    'https://blog.beanfield.com/beanfield-technologies-etend-sa-zone-de-couverture-optique-a-montreal-en-faisant-lacquisition-dopenface/';
  head() {
    return buildMeta({
      title: this.$t('openface:meta-title'),
      description: this.$t('openface:meta-description'),
      canonical: 'https://www.beanfield.com/openface',
      socialImage: 'welcome-to-beanfield',
      imageType: 'image/jpeg'
    });
  }
  layout() {
    return 'acquisition';
  }
}
